import {createStore} from "vuex";
// import createPersistedState from "vuex-persistedstate"
// import qs from "qs"
// import { util } from '@wnl/util'

export const store = createStore({
    state: {
        adminType:{},
        secondAdmin_info:[]
    },
    getters: {},
    mutations: {
        setAdminType(state,data){
            state.adminType.value = data
        },
        setSecondAdmin_info(state,data){
            state.secondAdmin_info.value = data
        }
    },
    actions: {
        getUserInfo(context) {
           
        }
    },
    modules: {}
});
