// import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'

// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: () => import('@/views/home')
//   },
//   {
//     path: '/manage',
//     name: 'manage',
//     component: () => import('@/views/manage')
//   }
// ]

// const router = createRouter({
//   history: createWebHistory(),
//   routes
// })
// router.beforeEach((to, from, next) => {
//   // if (!window.sessionStorage.getItem(process.env.VUE_APP_TOKEN)) {
//   //   if (to.path == "/") {
//   //     next();
//   //   } else {
//   //     router.replace({ path: '/', query: { ...to.query } })
//   //   }
//   // } else {
//   //   next();
//   // }
//   const isAuthenticated = window.sessionStorage.getItem(process.env.VUE_APP_TOKEN);
//   console.log(isAuthenticated);
//   if (!isAuthenticated && to.path !== "/") {
//     console.log(1);
//     // 用户未登录且尝试访问非首页路径，保存原始路径信息并重定向到登录页面
//     next({ path: '/', query: { redirect: to.fullPath } });
//   } else {
//     console.log(2);
//     next();
//   }
// })
// export default router

import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import Home from '@/views/home/index.vue';
import Manage from '@/views/manage/index.vue';
import AdminManage from '@/components/adminManage.vue';
import UserManage from '@/components/userManage.vue';
import AddressManage from '@/components/addressManage.vue';
import JmqzAddressManage from '@/components/jmqzManage.vue';

const routes = [
  { path: '/', component:  Home },
  { path: '/login', component:  Home },
  { 
    path: '/Manage',
    component: Manage,
    children:[
      {
        path: '/AdminManage',
        component: AdminManage,
      },
      {
        path: '/UserManage',
        component: UserManage,
      },
      {
        path: '/AddressManage',
        component: AddressManage,
      },
      {
        path: '/JmqzAddressManage',
        component: JmqzAddressManage,
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});
router.beforeEach((to, from, next) => {
  let isLogin = localStorage.getItem('isLogin')
  if(isLogin=='false' && to.path !== "/"){
    next('/');
  }else{
    if(to.path == "/"){
      localStorage.setItem('isLogin',false)
    }
    next()
  }
  next()
})
export default router;
