<template>
  <div class="manage">
    <div class="topBar">
      <div class="topBar_title">博源智康后台管理</div>
      <div class="topBar_exit" @click="exitLogin">退出</div>
    </div>
    <div class="manage_content">
      <div class="manage_aside">
        <div class="aside_userName">管理员:{{adminType_info.value.adminname}}</div>
        <div class="aside_content">
          <ul>
            <li v-for="(item,i) in select_asideLisr" :key="i" @click="routerClick(item.asideUrl,i)" :class="[{'select_aside':selectIndex==i}]">{{ item.asideName }}</li>
          </ul>
        </div>
      </div>
      <div class="manage_show">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, getCurrentInstance, onMounted, ref,onBeforeMount } from 'vue';
import { Button, Field, showToast   } from 'vant'
import {useRouter } from 'vue-router';
import { store } from '@/store/index';

import { getSecondAdmin } from '@/api/index'
// const store = useStore();
import 'vant/lib/index.css';
const router = useRouter();
const selectIndex = ref(0)
const select_asideLisr = ref([
  {'asideName':'增加管理员','asideUrl':'/AdminManage'},
  {'asideName':'用户管理','asideUrl':'/UserManage'},
  {'asideName':'一针断痔','asideUrl':'/AddressManage'},
  {'asideName':'静脉曲张','asideUrl':'/JmqzAddressManage'},
])
const secondAdmin_info = ([])
const adminType_info = ({})
onBeforeMount(()=>{
  let logInfo = JSON.parse(localStorage.getItem('logInfo'))
  adminType_info.value = logInfo
  if(adminType_info.value.admintype==1){
    let secondInfo = JSON.parse(localStorage.getItem('secondRes'))
    secondAdmin_info.value = secondInfo
    store.commit('setAdminType',secondAdmin_info.value)
  }
  store.commit('setAdminType',logInfo)
})
onMounted(()=>{
  let urlindex = localStorage.getItem('urlindex')
  selectIndex.value = urlindex
  console.log(urlindex)
  // v-if="adminType_info.value.admintype==1"
  if(adminType_info.value.admintype==2){
    select_asideLisr.value = [ 
      {'asideName':'用户管理','asideUrl':'/UserManage'},
      {'asideName':'一针断痔','asideUrl':'/AddressManage'},
      {'asideName':'静脉曲张','asideUrl':'/JmqzAddressManage'}
    ]
  }
})
const routerClick = (url,i)=>{
  selectIndex.value = i
  router.push(url);
  localStorage.setItem('urlindex',i)
}
const exitLogin = ()=>{
  localStorage.setItem('isLogin',false)
  router.push('/');
}
</script>
<style scoped>
.manage {
  width: 100%;
  height: 100%;
  user-select: none;
}

.topBar {
  width: 100%;
  height: 60px;
  text-align: right;
  line-height: 60px;
  background: rgba(22, 22, 245, 0.3);
  color: #fff;
  box-sizing: border-box;
}

.topBar_title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.topBar_exit {
  color: black;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  right: 30px;
}
.manage_content{
  width: 100%;
  height: 100%;
  display: flex;
}
.manage_show{
  width: 100%;
  height: 100%;
}
.manage_aside {
  width: 190Px;
  height: 100%;
  background: rgba(22, 22, 245, 0.3);
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  white-space: nowrap;
}

.aside_userName {
  font-size: 17px;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  padding: 15px 0;
  box-sizing: border-box;
}

.aside_content {
  font-size: 19px;
  font-weight: 600;
}

li {
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
}
.select_aside{
  background: #fff !important;
  color: black !important;
}

</style>