/*
 * @Author       : zxlin
 * @Date         : 2023-06-05 10:37:06
 * @LastEditors  : zxlin
 * @LastEditTime : 2023-09-13 10:36:51
 * @FilePath     : \h5-vue3-template\src\utils\window-recalc.js
 * @Description  : rem适配
 */
// 规定默认的设计稿宽度750px
const designHeight = 750
const html = document.documentElement

let state = 'Width'
function reCalc() {
  const windowHeight = html[`client${state}`] < designHeight ? html[`client${state}`] : designHeight
  // *100 之后，则样式中rem的值就需要相应的缩小100倍
  // 即：设计稿中的20px，在样式中就要写成0.2rem
  const fontSize = windowHeight / designHeight * 100

  setFontSize(fontSize)
}

function setFontSize(fontSize) {
  html.style.fontSize = `${fontSize}px`
}

// 监听resize
window.addEventListener('resize', reCalc)
reCalc()


//判断手机横竖屏状态：
function changeState() {
  if (window.orientation == 180 || window.orientation == 0) {
    //竖屏
    state = 'Width'
    reCalc()
  }
  if (window.orientation == 90 || window.orientation == -90) {
    //横屏
    state = 'Height'
    reCalc()
  }
}

changeState()
window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", changeState, false)