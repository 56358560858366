<template>
  <div class="userManage">
    <div class="show_user">
      <div class="notUser" v-if="show_data==null ||  Object.keys(show_data).length===0">暂无用户</div>
      <div class="show_uesr_content" v-else>
        <div class="search_input">
          <Search v-model="search_val" :clearable="false" @update:model-value="search_query"></Search>
          <div class="search_list" v-if="actions.length>0">
            <p v-for="(item,i) in actions" :key="i" @click="select_search(item)">{{ item.username }}</p>
          </div>
        </div>
        <ul>
          <li>
            <p>省份</p>
            <p>店铺名称</p>
            <p>被授权人</p>
            <p>授权证书编号</p>
            <p>授权日期</p>
            <p>操作</p>
          </li>
          <li v-for="(item,i) in show_data" :key="i" :class="{'searchbg':search_bg==i}">
            <p v-for="(items,a) in item" :key="a"> {{items }}</p>
            <p class="user_control"><span @click="delUser(item)">删除</span> <span @click="modifyUser(item)">修改</span></p>
          </li>
        </ul>
      </div>
      <div class="add_user" @click="sub_overlay=true">+</div>
    </div>
    <div class="userManage_buttom" v-if="show_data != null">
      <Pagination v-model="totalPage" :total-items="pageInfo.total_items" :items-per-page="pageInfo.items_per_page" @change="cahangePage" v-if="pageInfo.total_items>10"/>
      <span>{{ '共: '+((totalPage-1)*15+show_data.length)+'/'+pageInfo.total_items }}</span>
      </div>
    <Overlay :show="sub_overlay" @click="sub_overlay = false">
      <div class="wrapper" @click.stop>
        <div class="add_user_overlay">
          <Loading color="#1989fa" vertical  v-if="upLoad_loading">上传中...</Loading>
          <div class="add_userType">
            <p :class="[{'change_update':add_user_type==1}]" @click="upLoad_barChange(1)">单条</p>
            <p :class="[{'change_update':add_user_type==2}]" @click="upLoad_barChange(2)">excel导入</p>
          </div>
          <div class="one_data" v-if="add_user_type==1">
            <!-- <Field placeholder="登记时间" type="text"></Field>登记时间 -->
            <Form @submit="submitUserInfo">
              <Field :rules="[{ required: true, message: '请填写省份' }]" placeholder="省份（必填）" type="text" style="font-size: 17px;" v-model="oneDateInfo.province"></Field><!--省份-->
              <Field :rules="[{ required: true, message: '请填写店铺名称' }]" placeholder="店铺名称（必填）" type="text" style="font-size: 17px;" v-model="oneDateInfo.shopsName"></Field><!--店铺名称（必填）-->
              <Field :rules="[{ required: true, message: '请填写被授权人' }]" placeholder="被授权人（必填）" type="text" style="font-size: 17px;" v-model="oneDateInfo.userName"></Field><!--被授权人-->
              <Field :rules="[{ required: true, message: '请填写授权证书编号' }]" placeholder="授权证书编号（必填）" type="text" style="font-size: 17px;" v-model="oneDateInfo.codeNum"></Field><!--授权证书编号-->
              <Field :rules="[{ required: true, message: '请填写授权日期' }]" placeholder="授权日期（必填）" type="text" style="font-size: 17px;" v-model="oneDateInfo.time"></Field><!--授权日期（yyyy-MM-dd)-->
              <Button type="primary" size="large" class="submit" native-type="submit">提交</Button>
            </Form>
          </div>
          <div class="all_data" v-else>
            <input type="file" accept=".xls,.xlsx" @change="handleFileUpload" class="fileUploader"/>
          </div>
        </div>
      </div>
      <div class="allData_list" v-if="excelData.length!=0" @click.stop>
        
        <Loading color="#1989fa" vertical v-if="upLoad_loading">上传中...</Loading>
        <div class="allList_title">合作商信息登记</div>
        <div class="allData_list_close" @click="excelData=[]"></div>
        <ul>
          <li>
            <p>省份</p>
            <p>店铺名称</p>
            <p>被授权人</p>
            <p>授权证书编号</p>
            <p>授权日期</p>
          </li>
          <li v-for="(item,i) in excelData" :key="i">
            <p v-for="(items,a) in item" :key="a">{{ items }}</p>
          </li>
        </ul>
        <Button type="primary" size="small" class="all_submit" @click="excel_Btn">提交</Button>
      </div>
    </Overlay>
  <!-- 修改用户数据 -->
  <Overlay :show="modify_isShow">
    <div class="modify_user" @click.stop>
      <div class="closeModify" @click="closeModify"><img src="@/assets/imgs/nzsz_gb_icon.png"></div>
      <div class="modify_uer_title">修改用户</div>
      <Form @submit="sub_modifyUser">
        <Field :rules="[{ required: true, message: '请填写被授权人' }]" placeholder="被授权人（必填）" type="text" style="font-size: 17px;" v-model="modify_user_data.userName"></Field><!--被授权人-->
        <Field :rules="[{ required: true, message: '请填写省份' }]" placeholder="省份（必填）" type="text" style="font-size: 17px;" v-model="modify_user_data.province"></Field><!--省份-->
        <Field :rules="[{ required: true, message: '请填写店铺名称' }]" placeholder="店铺名称（必填）" type="text" style="font-size: 17px;" v-model="modify_user_data.shopsName"></Field><!--店铺名称（必填）-->
        <Field :rules="[{ required: true, message: '请填写授权证书编号' }]" placeholder="授权证书编号（必填）" type="text" style="font-size: 17px;" v-model="modify_user_data.codeNum"></Field><!--授权证书编号-->
        <Field :rules="[{ required: true, message: '请填写授权日期' }]" placeholder="授权日期（必填）" type="text" style="font-size: 17px;" v-model="modify_user_data.time"></Field><!--授权日期（yyyy-MM-dd)-->
        <Button type="primary" size="large" class="submit" native-type="submit">提交</Button>
      </Form>
    </div>
  </Overlay>
  </div>
</template>
<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import { Overlay, Field, Button,Search , Popover  , showToast, Pagination,Form,Uploader,Loading,showLoadingToast  } from 'vant'
import {useRouter } from 'vue-router';
import { update_strip,update_excel,getDataCount,getAuthAll } from '@/api/index'
import 'vant/lib/index.css';
import * as XLSX from 'xlsx';
import { flags } from 'postcss-pxtorem/lib/pixel-unit-regex';
const page = getCurrentInstance()
// 搜索框输入值
const search_val = ref('')
const search_bg = ref(-1)
// 修改用户的数据
const modify_user_data = ref()
const modify_isShow = ref(false)
// 提交蒙层
const sub_overlay = ref(false)
// 添加用户的方式
const add_user_type = ref(1) //1:单条 2:多条
// 展示数据
const show_data = ref({})
// 单挑提交
const oneDateInfo = ref({
  province:'',
  shopsName:'',
  userName:'',
  codeNum:'',
  time:''
})
const actions = ref([]);
// excel上传数据
const excelData = ref([])
// 数据总条数
const dataTotal = ref(0)
// 分页器数据
const totalPage = ref(1)
const pageInfo = ref({
  items_per_page:15,
  total_items:0,
  show_page_size:10
})
onMounted(async ()=>{
  let countRes = await getDataCount()
  dataTotal.value = countRes.data
  pageInfo.value.total_items = countRes.data
  getShow_authData()
})
const search_query = async ()=>{
  const textLeg = /[\u4e00-\u9fff]/ //汉字验证
  if(search_val.value==''){
    actions.value =[]
  }
  if(!textLeg.test(search_val.value)){
    return
  }
  let res = await page.proxy.$apiGet('/vagueQuery',{userName:search_val.value})
  actions.value =[...res.data]
}
const select_search = async (item) =>{
  let target_index = Math.ceil(item.target_index/15)
  totalPage.value = target_index
  getShow_authData()
  actions.value = []
  search_val.value = ''
  search_bg.value = (item.target_index-1)%15
}
// 上传等待
const upLoad_loading = ref(false)
// 提交用户信息
const submitUserInfo = ()=>{
  const textLeg = /[\u4e00-\u9fff]/ //汉字验证
  if(!textLeg.test(oneDateInfo.value.province)){
    oneDateInfo.value.province = ''
    showToast('省份仅支持汉字')
    return
  }
  if(!textLeg.test(oneDateInfo.value.userName)){
    showToast('被授权人仅支持汉字')
    oneDateInfo.value.userName = ''
    return
  }
  // 数字验证正则
  const integerRegex = /^\d+$/;
  if(!integerRegex.test(oneDateInfo.value.codeNum)){
    showToast('授权码及支持纯数字')
    oneDateInfo.value.codeNum = ''
    return
  }
  if(!checkValueType(oneDateInfo.value.time)){
    showToast('日期格式有误')
    oneDateInfo.value.time = ''
    return
  }
  upLoad_loading.value = true
  setTimeout(async()=>{
    await update_strip({...oneDateInfo.value})
    getShow_authData()
    upLoad_loading.value = false
    sub_overlay.value=false,
    oneDateInfo.value.province = ''
    oneDateInfo.value.userName = ''
    oneDateInfo.value.shopsName = ''
    oneDateInfo.value.codeNum = ''
    oneDateInfo.value.time = ''
  },500)
}
// 上传的bar选择
const upLoad_barChange = (item)=>{
  if(upLoad_loading.value){ return }
  add_user_type.value = item
}
// excle表单校验
const handleFileUpload = (event)=>{
  let excel_data_loading = showLoadingToast({
    message: 'excel解析中...',
    loadingType: 'spinner',
    duration:0
  });
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    // 处理jsonData，例如将其存储到Vuex或者状态中
    const filteredData = jsonData.filter(row => row.length > 0);
    
    console.log(filteredData)
    let headers = filteredData[0]
    let index = 0
    for(let i = 0;i<headers.length;i++){
      if (headers[i] === '地区') { index = i}
    }
    let lastIsTime = false
    if(headers[headers.length-1]=='授权编码'){
      lastIsTime = true
    }
    let timeIndex = 0
    for(let i = 0;i<headers.length;i++){
      if (headers[i] == '授权日期') { 
        timeIndex = i
      }
    }
    const cleanedData = filteredData.map(row => row.slice(index));

    let trimmedData = cleanedData.slice(1);
    trimmedData = trimmedData.map(row => row.slice(0));
    if(lastIsTime){
      trimmedData.map(row =>{
        var arr = row;
        // 交换数组中的最后两项
        var temp = arr[arr.length - 1];
        arr[arr.length - 1] = arr[arr.length - 2];
        arr[arr.length - 2] = temp;
        row = arr
      })
    }
    trimmedData.map(row =>{
     for(let i = 0;i<row.length;i++){
      if(i == 4){
          var numericPattern = /^\d+$/;
          if(numericPattern.test(row[i])){
            row[i] = excelDateToJSDate(row[i])
          }
         
      }
    }
    });
    excel_data_loading.close()
    if(trimmedData.length==0) {
      showToast('该表格无数据，请重新选择')
      return
    }
    excelData.value = trimmedData

  };
  reader.readAsArrayBuffer(file);
}
// excel提交
const excel_Btn = async()=>{
  show_data.value = excelData.value
  upLoad_loading.value = true
  for(let i=0;i<excelData.value.length;i++){
    let res = await update_excel(excelData.value[i])
    if(res.data=='已存在'){
      showToast('授权码:'+excelData.value[i][3]+'已存在')
      upLoad_loading.value = false
    }
  }
  getShow_authData()
  setTimeout(()=>{ 
    excelData.value = [],
    sub_overlay.value=false,
    upLoad_loading.value = false
  },500)
}
// 获取展示数据
const getShow_authData = async ()=>{
  let authListRes = await getAuthAll({pageNum:totalPage.value})
  console.log(authListRes)
  show_data.value = authListRes.data.rows
}
// 分页切换
const cahangePage = async ()=>{
  getShow_authData()
  search_bg.value = -1
}
// 检测传入的是不是日期
const isNumeric = (value) =>{
  return !isNaN(parseFloat(value)) && isFinite(value);
}
const isDateStr = (value) => {
  const dateFormats = [/^\d{4}\/\d{1,2}\/\d{1,2}$/, /^\d{4}\.\d{1,2}\.\d{1,2}$/, /^\d{4}-\d{1,2}-\d{1,2}$/];
    for (let format of dateFormats) {
        if (format.test(value)) {
            return true;
        }
    }
    return false;
}

const checkValueType = (value) => {
  if (isNumeric(value)) {
        return false;
    } else if (isDateStr(value)) {
        return true;
    } else {
        return false;
    }
}
// 删除用户
const delUser = (item)=>{
  const isDel = confirm('是否确认删除,数据删除后将无法恢复')
  if(isDel){
    let delUserLoading = showLoadingToast({
      message: '删除中...',
      loadingType: 'spinner',
      duration:0
    });
    setTimeout(async()=>{
      let res = await page.proxy.$apiGet('/deluser',{codenum:item.codeNum})
      if(res.data ==1){
        showToast('删除成功')
      }else{
        showToast('删除失败')
      }
      delUserLoading.close()
      getShow_authData()
    },300)
  }
}
// 修改用户
const modifyUser=(item)=>{
  let modify_data = item
  modify_isShow.value = true
  modify_user_data.value = modify_data
  modify_user_data.value.notCodeNum = modify_data.codeNum
}
const sub_modifyUser = async()=>{ 
  let modiUserLoading = showLoadingToast({
      message: '更新中...',
      loadingType: 'spinner',
      duration:0
    });
    setTimeout(async()=>{
      let res = await page.proxy.$apiGet('/modifyUser',{...modify_user_data.value})
      showToast('修改成功')
      modiUserLoading.close()
      getShow_authData()
      modify_isShow.value = false
      modify_user_data.value = []
    },300)
  getShow_authData()
}
const closeModify = async()=>{
      getShow_authData()
      modify_isShow.value = false
      modify_user_data.value = []
 }

//  excel时间转换
const excelDateToJSDate = (excelDate)=>{
  const startDate = new Date(1900, 0, 1);
  startDate.setDate(startDate.getDate() + excelDate);
  var year = startDate.getFullYear();
  var month = startDate.getMonth() + 1; // 月份从0开始，所以要加1
  month = month>10? month :'0'+month
  var day = startDate.getDate();
  day = day>10? day :'0'+day
  // 输出结果
  return `${year}-${month}-${day}`
}
</script>
<style scoped>
.userManage {
  width: 100%;
  height: 100%;
  user-select: none;
}

.show_user {
  width: 100%;
  height: 85vh;
  position: relative;
}
.notUser{
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 70px;
  color:#ddd;
  transform: translate(-50%,-50%);
}
.show_uesr_content{
  width: 100%;
  height: 100%;
  /* background: rebeccapurple; */
}
.search_input{
  width: 350px;
  margin: 0 auto;
  margin-top: 15px;
  position: relative;
}
.search_list{    
  width: 93%;
  max-height: 300px;
  position: absolute;
  left: 0.13rem;
  top: 0.42rem;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1003;
  font-size: 15px;
  overflow: auto;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1),-5px -5px 10px rgba(0, 0, 0, 0.1);
  padding:10px 0px ;
  line-height: 28px;
  font-size: 17px;
}
.search_list p{
  cursor: pointer;
  padding:0px 17px ;
}
.search_list p:hover{
  background: #f1f1f1;
}
.show_uesr_content ul{
  width: 100%;
  height: 90%;
  position: relative;
  overflow: auto;
  padding-top: 20px;
}
.show_uesr_content li{
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  position: relative;
  align-items: center;
  min-height: 20px;
  cursor: pointer;
}
.show_uesr_content li:not(:first-of-type):hover{
  color: #1989fa;
}
.show_uesr_content li:first-child{    
  padding-bottom: 7px;
  font-size: 17px !important;
  font-weight: 600; 
  padding: 0px !important;
  height: 30px;
  line-height: 30px;
}
.show_uesr_content li p{
  flex: 0.2;
  text-align: center;
  font-size: 13px;
}
.user_control span{
  cursor: pointer;
  text-decoration: underline;
  color: rgb(50, 119, 184);
}
.user_control span:first-of-type{
  color: rgb(206, 67, 67);
}
.show_uesr_content li:first-child p{
  text-align: center;
  font-size: 18px;
  font-weight: 600 !important;
}
.userManage_buttom{
  margin: 0 auto;
  margin-top: 0px;
  width: 380px;
  display: flex;
  align-items: center;
}
.userManage_buttom>span{
  color: #1989fa;
  font-size: 16px;
  text-wrap: nowrap;
  margin-left: 13px;
}
.add_user{
  font-size: 70px;
  background: rgba(22, 22, 245, 0.3);
  position: absolute;
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 70px;
  color: #fff;
  border-radius: 50%;
  bottom: 0px;
  right: 60px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1),-5px -5px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.add_user_overlay{
  width: 300px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: 20px;
  text-align: center;
  border-radius: 15px;
  padding: 15px;
  box-sizing: border-box;
  overflow: hidden;
}
.add_userType{
  display: flex;
  justify-content: space-between;
  text-align: center;
  border-bottom:  1px solid #ddd;
}
.add_userType>p{
  flex: 0.5;
  cursor: pointer;
  padding-bottom: 7px;
}
.add_userType>p:first-of-type{
  border-right: 1px solid #ddd;
}
.change_update{
  color: rgba(22, 22, 245, 0.3) !important;
}
.one_data,.submit{
  margin-top: 15px;
}
.fileUploader{
  font-size: 17px;
  margin: 22px 0;
}
.allData_list{
  width: 70%;
  height: 80%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: 15px;
  text-align: center;
  padding: 30px 0px;
}
.allList_title{
  width: 100%;
  height: 88px;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 15px;
  line-height: 70px;
}
.allData_list ul{
  height: 100%;
  overflow: auto;
  font-size: 19px;
  padding-top: 70px;
  box-sizing: border-box;
  padding-left: 15px;
}
.allData_list li{
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
}
.allData_list li:first-child{    
  position: fixed;
  left: 15px;
  top: 65px;
  width:99%;
  padding-bottom: 7px;
}
.allData_list li:first-child>p{
  font-size: 19px;
  font-weight: 600;
}
.allData_list li>p{
  flex: 0.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}
.allData_list_close{
  width: 15px;
  height: 15px;
  background: url('@/assets/imgs/nzsz_gb_icon.png') 100% 100% no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 15px;
  top: 25px;
  cursor: pointer;
}
.all_submit{
  position: absolute;
  right: 15px;
  bottom:15px;
}
.van-loading{
  height: 90px;
  width: 90px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 4001;
  transform: translate(-50%,-50%);
}
.modify_user{
  width: 300px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: 18px;
  text-align: center;
  border-radius: 15px;
  padding: 15px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 600;
}
.closeModify{
  width:10px;
  height: 10px;
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 10px;
}
.closeModify>img{
  width:10px;
  height: 10px;
}
.searchbg{
  background: #f0efef;
}
</style>