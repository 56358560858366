/*
 * @Author       : zxlin
 * @Date         : 2023-11-22 14:56:23
 * @LastEditors  : zxlin
 * @LastEditTime : 2023-11-23 11:37:16
 * @FilePath     : \h5-vue3-template\src\utils\pre.js
 * @Description  : 
 */
const getNavigationTiming = () => {
  const resolveNavigationTiming = (entry) => {
    const {
      domainLookupStart,
      domainLookupEnd,
      connectStart,
      connectEnd,
      secureConnectionStart,
      requestStart,
      responseStart,
      responseEnd,
      domInteractive,
      domContentLoadedEventEnd,
      loadEventStart,
      fetchStart,
    } = entry

    return {
      // 关键时间点
      FP: responseEnd - fetchStart,
      TTI: domInteractive - fetchStart,
      DomReady: domContentLoadedEventEnd - fetchStart,
      Load: loadEventStart - fetchStart,
      FirstByte: responseStart - domainLookupStart,
      // 关键时间段
      DNS: domainLookupEnd - domainLookupStart,
      TCP: connectEnd - connectStart,
      SSL: secureConnectionStart ? connectEnd - secureConnectionStart : 0,
      TTFB: responseStart - requestStart,
      Trans: responseEnd - responseStart,
      DomParse: domInteractive - responseEnd,
      Res: loadEventStart - domContentLoadedEventEnd,
    }
  }

  const navigation =
    performance.getEntriesByType('navigation').length > 0
      ? performance.getEntriesByType('navigation')[0]
      : performance.timing
  return resolveNavigationTiming(navigation)
}
window.onload = function () {
  const p = getNavigationTiming()
  const table = Object.keys(p).map(i => {
    return {
      '性能指标': i,
      '时间': `${p[i].toFixed(2)}ms`
    }
  })
  console.log(
    `%c 页面加载总耗时 %c Load %c ${p.Load.toFixed(2)}ms`,
    "background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff",
    "background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff",
    "background:transparent"
  )
  console.table(table)
}