/*
 * @Author       : zxlin
 * @Date         : 2023-06-05 10:07:07
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-03-28 11:07:44
 * @FilePath     : \h5-vue3-template\src\utils\wnl-util.js
 * @Description  : 万年历工具类
 */
import { UM, util, wnlsdk } from "@wnl/util"
import { wxShare, wnlShare } from '@wnl/share'
window.util = util

const UMkey = 'UMkey'
UM.initUM(UMkey)
window.UM = UM
window.wnlsdk = wnlsdk

//封装UM
window.UMHook = (function () {
  const type = '运营活动'
  return function (key, option = {}) {
    if (type === '运营活动') {
      if (window.util.isWnl) {
        Object.keys(option).length > 0 ? window.UM.hmCK(key + '_wnl', option) : window.UM.hmCK(key + '_wnl')
      } else {
        Object.keys(option).length > 0 ? window.UM.hmCK(key + '_wx', option) : window.UM.hmCK(key + '_wx')
      }
    } else {
      Object.keys(option).length > 0 ? window.UM.hmCK(key, option) : window.UM.hmCK(key)
    }
  }
})()

function parseSearchParams(searchParamsString) {
  var obj = {}
  var arr = (searchParamsString.split('?')[1] || '').split('&')
  for (var i = 0; i < arr.length; i++) {
    var res = arr[i].split('=')
    obj[res[0]] = res[1]
  }
  return obj
}

//测算
window.getCesuan = async (callback) => {
  if (!window.util.isWnl) {
    return
  }
  let posId = parseSearchParams(location.href)['posId'] || 'default'
  // 线上环境
  let pex = '//r.51wnl-cq.com'
  let res = await fetch(
    `${pex}/api/OperationalActivity/GetOperationalActivityProducts?cid=${window.util.isAndroid ? 'Youloft_Android' : 'Youloft_IOS'
    }&av=${window.util.appVersionString}&chn=${window.wnlsdk.getDValue(
      'CHANNEL'
    )}&citycode=${window.wnlsdk.getDValue('CITYID')}&code=${posId}`
  ).then((res) => res.json())
  if (res.data.length > 0) {
    callback(res.data[0].links, res.data[0].images)
  }
}

// 修改share
window.changeShare = (shareInfo, callback) => {
  const msgTitle = require('@/assets/imgs/msg_title.jpg')
  const msgTitlePath =
    window.location.href
      .slice(0, window.location.href.lastIndexOf('/') + 1)
      .slice(0, -2) + msgTitle
  const mainPath = window.location.href.slice(
    0,
    window.location.href.lastIndexOf('/') + 1
  )
  const query = Object.entries(parseSearchParams(location.href))
    .reduce(
      (searchParams, [name, value]) => (
        searchParams.append(name, value), searchParams
      ),
      new URLSearchParams()
    )
    .toString()
  window.shareCallback = () => {
    callback()
  }
  let share = {
    title: shareInfo.title,
    timelineTitle: shareInfo.title,
    text: shareInfo.text,
    image: msgTitlePath,
    imgUrl: msgTitlePath,
    url: shareInfo.url || `${mainPath}?${query}`,
    callback: window.shareCallback,
  }
  if (window.util.isWnl) {
    wnlShare.setShareData(share)
  }
  if (window.util.isWeixin) {
    new wxShare(share)
  }
}
// 解决万年历分享框初始化弹出问题
window.addEventListener('DOMContentLoaded', function () {
  let dom = document.querySelector('.wnlshare-platform')
  if (dom) {
    if (window.util.isWnl) {
      dom.style.display = 'none'
      setTimeout(() => {
        dom.style.display = ''
      }, 300)
    } else {
      dom.style.display = 'none'
    }
  }
})