/*
 * @Author       : zxlin
 * @Date         : 2023-07-06 11:34:05
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-04-08 09:15:42
 * @FilePath     : \h5-vue3-template\src\main.js
 * @Description  : 
 */
import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import '@/styles/index.css'
import VConsole from 'vconsole'
import um from '@/directives/um'
import { apiGet } from '@/api/index'
// 万年历工具类挂载window
import '@/utils/wnl-util'
// 使用rem适配
import '@/utils/window-recalc'
// 如果页面需要转繁体,打开下面注释
// import 'lang-hant'
// 性能
import '@/utils/perf'
import {store} from './store';
const app = createApp(App)
app.config.globalProperties.$apiGet = apiGet
app.use(store)
app.use(router)
// 友盟点击埋点指令
// app.directive('um', um)
app.mount("#app")