<template>
  <div class="home">
    <div class="login">
      <div class="login_title">博源智康</div>
      <div class="login_content">
        <Field placeholder="请输入用户名" class="field" maxlength="20"  type="text" v-model="loginInfo.userName"/>
        <Field placeholder="请输入密码" class="field" maxlength="20" type="password"  v-model="loginInfo.password"/>
        <Button type="primary" size="large" class="confirm" @click="loginConfrim">登录</Button>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { computed, getCurrentInstance, onMounted, ref } from 'vue';
  import { Button, Field, showToast   } from 'vant'
  import { adminLogin,getSecondAdmin } from '@/api/index'
  import {useRouter } from 'vue-router';
  import { store } from '@/store/index';
  import 'vant/lib/index.css';
  const router = useRouter();
  const secondUrl = ref('')
  const loginInfo = ref({userName:'',password:''})
  const handleClear = (loginType)=>{
    if(loginType=='username'){
      loginInfo.value.userName = ''
    }
  }
  const loginConfrim = async()=>{
    let { userName,password } = loginInfo.value
    if(userName=='' || password==''){
      showToast('用户名或密码不能为空')
      return
    }

    let loginRes = await adminLogin({adminname:userName,adminpassword:password})
    if(loginRes.data.length>0){
      localStorage.setItem('isLogin',true)
      if(loginRes.data[0].admintype==1){
        secondUrl.value = '/AdminManage'
        let secondRes = await getSecondAdmin({admintype:2})
        // 二级管理员信息
        localStorage.setItem('secondRes',JSON.stringify(secondRes.data))
      }else{
        let urlList = loginRes.data[0].adminurl.split(',')
        secondUrl.value =urlList[0]
      }
      // 管理员登录信息
      localStorage.setItem('logInfo',JSON.stringify(loginRes.data[0]))
      localStorage.setItem('urlindex',0)
      setTimeout(()=>{
        router.push(secondUrl.value)
      },200)
      
    }else{
    console.log(loginRes)
      showToast('用户名或密码错误')
    }
  }
</script>
<style scoped>
.home {
  background: rgba(241, 243, 241 , 0.2) no-repeat;
  background-position: center 0;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.login{
  width:450px;
  min-height:150px;
  background: #f8f6f6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 15px;
  text-align: center;
  padding: 30px 20px;
  box-sizing: border-box;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1),-5px -5px 10px rgba(0, 0, 0, 0.1);
}
.login_title{
  font-size: 30px;
}
.login_content{
  padding-top: 30px;
}
.field{
  width: 100% !important;
  height: 50px !important;
  font-size: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  line-height: 30px;
}
.confirm{
  font-size: 26px;
}
</style>
