<template>
  <router-view />
</template>
<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import {useRouter } from 'vue-router';
// 三十分钟无操作退出
import { startAutoLogout } from '@/utils/exitTimer'
const router = useRouter();
onMounted(()=>{
  if(localStorage.getItem('isLogin')==null){
    localStorage.setItem('isLogin',false)
  }
  startAutoLogout()
  console.log('11111')
})
</script>
