<template>
  <div class="adminManage">
    <div class="notAdmin" v-if="adminList.length==0 || adminList.length==null">暂无管理员</div>
    <div class="admin_info" v-else>
      <ul>
        <li v-for="(item,i) in adminList" :key="i">
          <p><span>{{ parseInt(i+1) }}.</span></p>
          <p>用户名:<span>{{ item.adminname }}</span></p>
          <p>密码: <span>{{ item.adminpassword }}</span></p>
          <p>创建时间: <span>{{ item.createtime }}</span></p>
          <div class="admin_control">
            <p @click="del_admin(i)">删除</p>
            <p @click="modifyAdminClick(i)">修改</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="add_admin" @click="add_admin">+</div>
    <Overlay :show="show">
      <div class="wrapper" @click.stop>
        <div class="block"> 
          <div class="block_tips">tips:用户名/密码不能超过20个字符</div>
          <div class="add_admin_title">{{overlay_title}}</div>
          <div class="add_admin_close" @click="exit_add_overlay"></div>
          <div class="add_admin_content">
            <Field class="field" placeholder="请输入用户名(仅支持字母)" v-model="modifyAdmin.adminname" maxlength="20" type="text"/>
            <Field class="field" placeholder="请输入密码" v-model="modifyAdmin.adminpassword" type="text" maxlength="20"/>
            <Button type="primary" size="large" class="confirm" @click="loginConfrim">确定</Button>
          </div>
        </div>
      </div>
    </Overlay>
  </div>
</template>
<script setup>
import { computed, getCurrentInstance, onMounted, ref, onBeforeMount } from 'vue';
import { Overlay, Field, Button, showConfirmDialog, showToast  } from 'vant'
import { store } from '@/store/index';
import {useRouter } from 'vue-router';
import 'vant/lib/index.css';
import { createAdmin,modifySecondAdmin,delAdmin } from '@/api/index'
const show = ref(false)
const page = getCurrentInstance()
// Overlay的title
const overlay_title = ref('')
// 修改管理员的角标
const modifyIndex = ref(-1)
const modifyAdmin = ref({adminname:'',adminpassword:'',createtime:''})
const adminList = ref([])

onBeforeMount(async ()=>{
    let secondInfo = JSON.parse(localStorage.getItem('secondRes'))
    console.log(secondInfo)
    if(secondInfo.length>0){
      adminList.value = ([...secondInfo])
    }
})
onMounted(()=>{
})
// 关闭添加用户的页面
const exit_add_overlay = () =>{
  show.value = false
  modifyAdmin.value = {'adminname':'','adminpassword':null,'createtime':''}
}
// 增加管理员
const add_admin = () =>{
  overlay_title.value = '添加管理员'
  show.value = true
}
// 修改用户
const modifyAdminClick = (i)=>{
  modifyAdmin.value = {...adminList.value[i]}
  show.value = true
  overlay_title.value = '修改管理员'
  modifyIndex.value = i
}
//删除管理员
const del_admin = (i)=>{
  showConfirmDialog({
    title: '删除',
    message: '是否确认删除该管理员,删除后无法恢复'
  }).then(() => {
      console.log('OK');
      let {adminname,adminpassword} = adminList.value[i]
      adminList.value.splice(i, 1);
      delAdmin({'adminName':adminname,'adminpassword':adminpassword})
      localStorage.setItem('secondRes',JSON.stringify(adminList.value))
    }).catch(() => {
      console.log('not');
    });
}
//确定按钮
const loginConfrim = ()=>{
  const leg = /^[A-Za-z]+$/i;
  const textLeg = /[\u4e00-\u9fff]/
  if(!leg.test(modifyAdmin.value.adminname)){
    showToast('用户名仅支持字母')
    return
  }
  if(textLeg.test(modifyAdmin.value.adminpassword)){
    showToast('密码不支持汉字')
    return
  }
  if (modifyIndex.value!=-1){
    let {adminname,adminpassword} = adminList.value[modifyIndex.value]
    let mdyDate = {'mdyAdminName':modifyAdmin.value.adminname,'mdyAdminpassword':modifyAdmin.value.adminpassword,adminName:adminname,adminpassword: adminpassword}
    // console.log(adminList.value[modifyIndex.value])
    modifySecondAdmin({...mdyDate})
    adminList.value[modifyIndex.value] = {...modifyAdmin.value}
    modifyIndex.value = -1
      // modifySecondAdmin()
  }else{
      let date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth()+1
      month = month<10?'0'+month:month
      let day = date.getDate()
      day = day<10?'0'+day:day
      let timer = year+'-'+month+'-'+day
      // console.log(month)
      let createDate = {'adminName':modifyAdmin.value.adminname,'adminpassword':modifyAdmin.value.adminpassword,'createTime':timer}
      createAdmin({...createDate})
      modifyAdmin.value.createtime = timer
      adminList.value.push( { ...modifyAdmin.value})
  } 
  localStorage.setItem('secondRes',JSON.stringify(adminList.value))
  modifyAdmin.value = {'adminname':'','adminpassword':null,'createtime':''}
  show.value = false
}
</script>
  <style scoped>
  .adminManage {
    width: 100%;
    height: 100%;
    user-select: none;
    padding: 15px;
    box-sizing: border-box;
  }
  .notAdmin{
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 70px;
    color:#ddd;
    transform: translate(0%,-50%);
  }
  .add_admin{
    font-size: 70px;
    background: rgba(22, 22, 245, 0.3);
    position: absolute;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    color: #fff;
    border-radius: 50%;
    bottom: 50px;
    right: 60px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1),-5px -5px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }  
  .block {
    width: 400px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 20px;
    text-align: center;
    border-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
  }
  .add_admin_content,.confirm{
    margin-top: 30px;
  }
  .add_admin_title{
    font-weight: 600;
  }
  .field{
    margin-bottom: 15px;
    font-size: 20px;
  }
  .add_admin_close{
    width: 15px;
    height: 15px;
    background: url('@/assets/imgs/nzsz_gb_icon.png') 100% 100% no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 15px;
    top: 23px;
    cursor: pointer;
  }
  .admin_info{
    font-size: 23px;
  }
  .admin_info li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid rgba(22, 22, 245, 0.3);
  }
  .admin_info li>p{
    font-size: 20px;
    white-space: nowrap;
    text-align: left !important;
  }
  .admin_info li>p:nth-of-type(2),.admin_info li>p:nth-of-type(3){
    width: 180px;
  }
  .admin_info li div{
    display: flex;
  }
  .admin_info li div p{
    margin-left: 10px;
    font-size: 15px;
    color: red;
    text-decoration: underline;
    cursor: pointer;
  }
  .admin_info li div p:last-of-type{
    color: blue;
  }
  .block_tips{
    position: absolute;
    left: 30px;
    top: 50px;
    font-size: 12px;
    color: #ddd;
  }
  .admin_control p{
    min-width: 30px;
    white-space: normal;
  }
  </style>