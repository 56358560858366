/*
 * @Author       : zxlin
 * @Date         : 2023-08-09 13:36:40
 * @LastEditors  : zxlin
 * @LastEditTime : 2023-10-10 17:02:34
 * @FilePath     : \h5-vue3-template\src\api\home.js
 * @Description  : 首页相关api
 */
import axios from '@/utils/axios'
const request = axios()

/**
 * demo
 * @returns
 */
// export function demo() {
//   return request({
//     method: 'get',
//     url: '/api/demo',
//   })
// }
export function apiGet(url_get,param){
  return request({
    method: 'get',
    url: url_get,
    params: param
  })
}
export function adminLogin(param) {
  return request({
    method: 'get',
    url: '/adminLogin',
    params: param
  })
}
//  获取所有二级管理员
export function getSecondAdmin(param) {
  return request({
    method: 'get',
    url: '/getSecondAdmin',
    params: param
  })

}
//  创建二级管理员
export function createAdmin(param) {
  return request({
    method: 'get',
    url: '/createSecondAdmin',
    params: param
  })
}
// 修改二级管理员
export function modifySecondAdmin(param) {
  return request({
    method: 'get',
    url: '/modifySecondAdmin',
    params: param
  })
}
// 删除二级管理员
export function delAdmin(param) {
  return request({
    method: 'get',
    url: '/delSecondAdmin',
    params: param
  })
}
// 单条上传
export function update_strip(param) {
  return request({
    method: 'get',
    url: '/update_strip_Auth',
    params: param
  })
}
// excel上传

export function update_excel(param) {
  return request({
    method: 'get',
    url: '/excelUpdata',
    params: param
  })
}

// 获取数据总数量

export function getDataCount(param) {
  return request({
    method: 'get',
    url: '/getdataCount',
    params: param
  })
}
// 获取所有授权人
export function getAuthAll(param) {
  return request({
    method: 'get',
    url: '/authAll',
    params: param
  })
}