
import axios from 'axios'
import { util } from '@wnl/util'
console.log(process.env)
export default function (baseUrl = process.env.VUE_APP_BASE_API, timeout = 10000, responseType = undefined) {
  const service = axios.create({
    baseURL: baseUrl,
    timeout,
    responseType,
  })
  service.interceptors.request.use(
    (config) => {
      
      // console.log(config.params)
      // query加入公共参数
      !config.params && (config.params = {})
      // cid
      // config.params['cid'] = util.isIOS ? 'Youloft_IOS' : 'Youloft_Android'
      // av
      // util.appVersionString && (config.params['av'] = util.appVersionString)
      // 公共参数
      // const paramsList = ['pushToken', 'pToken', 'mac', 'imei', 'idfa', 'channel', 'posId', 'boundId', 'cityId']
      // paramsList.forEach(key => {
      //   if (util.getQueryValue(key)) {
      //     !config.params[key] && (config.params[key] = util.getQueryValue(key))
      //   }
      // })
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  service.interceptors.response.use(
    (response) => {
      const res = (response || {}).data || {}
      return res
    },
    (error) => {
      // alert(error.toString())
      return (error || {}).response
    }
  )
  return service
}