<template>
    <div class="addressManage">
        <div class="addres_query_range" @click="addres_range"><Icon name="setting-o" /></div>
        <div class="not_data" v-if="show_data.length==0">暂无数据</div>
        <div class="show_data" v-else>
            <div class="show_data_title">
                <p>商铺名称</p>
                <p>所在省</p>
                <p>所在市</p>
                <p>所在县(区)</p>
                <p>详细地址</p>
                <p>操作</p>
            </div>
            <div class="show_data_ing">
                <ul>
                    <li v-for="(item,i) in show_data" :key="i">
                        <p>{{ item.shopsname }}</p>
                        <p>{{ item.province }}</p>
                        <p>{{ item.city }}</p>
                        <p>{{ item.county }}</p>
                        <p>{{ item.address }}</p>
                        <p><span class="delAddress" @click="delAddress_click(item)">删除</span></p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="paging">
            <Pagination v-model="totalPage" :total-items="pageInfo.dataTotal" :show-page-size="pageInfo.show_page_size" :items-per-page="pageInfo.items_per_page" @change="cahangePage" v-if="pageInfo.total_items>10"/>
        </div>
        <Overlay :show="add_address_show" @click="close_address_overlay">
            <div class="address_wrapper" @click.stop v-if="!seconde_isShow">
                <div class="add_address_type">
                    <p :class="[{'select_add_type':select_address_type==1}]" @click="upLoad_barChange(1)">单条</p>
                    <p :class="[{'select_add_type':select_address_type==2}]" @click="upLoad_barChange(2)">excel导入</p>
                </div>
                <div class="one_update_address" v-if="select_address_type==1">
                    <div class="one_update_tips">直辖市省份填写直辖市名</div>
                    <Form @submit="queryAddress">
                        <Field :rules="[{ required: true, message: '店铺名称' }]" placeholder="店铺名称（必填）" type="text" style="font-size: 17px;" v-model="one_update_address.shopsName"></Field><!--店铺名称-->
                        <Field :rules="[{ required: true, message: '请填写省份' }]" placeholder="省份（必填）" type="text" style="font-size: 17px;" v-model="one_update_address.province"></Field><!--省份-->
                        <Field :rules="[{ required: true, message: '请填写所在市' }]" placeholder="所在市（必填）" type="text" style="font-size: 17px;" v-model="one_update_address.city"></Field><!--市-->
                        <Field :rules="[{ required: true, message: '请填写所在区县' }]" placeholder="所在区县（必填）" type="text" style="font-size: 17px;" v-model="one_update_address.county"></Field><!--区县-->
                        <Field :rules="[{ required: true, message: '请填写详细地址' }]" placeholder="详细地址（必填）" type="text" style="font-size: 17px;" v-model="one_update_address.address"></Field><!--详细地址-->
                        <Button type="primary" size="large" class="submit" :loading="query_loading"  native-type="submit" loading-text="查询中...">上传</Button>
                    </Form>
                </div>
                <div class="excel_update_address" v-else>
                    <input type="file" accept=".xls,.xlsx" @change="handleFileUpload" class="fileUploader" id="fileInput"/>
                </div>
            </div>
        </Overlay>
        <div class="add_address" @click="add_address">+</div>
        <!-- <div class="second_confirm" v-if="seconde_isShow && add_address_show">
            <div class="address_confirm_title">地址确认</div>
            <div @click="close_second_confirm" class="close_second_address">
                <img src="@/assets/imgs/nzsz_gb_icon.png" >
            </div>
            <div class="Radio">
                <div v-if="excel_update_schedule.total>0" class="update_schedule">共<span>{{ excel_update_schedule.total }}</span>条 当前第<span>{{ excel_update_schedule.num+1 }}</span>条 </div>
                <div class="text_Address">
                    <p><span>店铺名称</span>:&nbsp; {{excel_update_schedule.total>0?excelData[excel_update_schedule.num][0] : one_update_address.shopsName }}</p>
                    <p><span>文本地址</span>:&nbsp; {{excel_update_schedule.total>0?excelData[excel_update_schedule.num][4] : one_update_address.address }}</p>
                </div>
                <RadioGroup v-model="checked" icon-size="15px">
                    <div style="font-weight: 600;">解析地址:</div>
                    <Radio :name="i" v-for="(item , i) in query_result" :key="i">
                        {{ item.formatted_address }}
                    </Radio>
                </RadioGroup>
            </div>
            <div class="second_btn">
                <Button type="success" size="small" class="add_address_submit" plain hairline @click="confirm_update">上传</Button>
            </div>
        </div> -->
        <!--  -->
        <div class="excel_data_show" v-if="excelData.length>0 && !seconde_isShow">
            <div class="excel_show_title">
                <span>授权商铺</span>
                <div @click="close_excel_data"><img src="@/assets/imgs/nzsz_gb_icon.png" ></div>
            </div>
            <div class="excel_show_type"><p>商铺名称</p><p>省</p><p>市</p><p>县(区)</p><p>详细地址</p></div>
            <div class="excel_show_content">
                <ul>
                    <li v-for="(item,i) in excelData" :key="i">
                        <p v-for="(items,a) in item" :key="a">{{a==0? (i+1)+'.'+items:items }}</p>
                    </li>
                </ul>
                <div class="excel_update" @click="excel_update">上传</div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, getCurrentInstance, onMounted, ref, onBeforeMount } from 'vue';
import { Overlay,Form, Field, Button, RadioGroup ,Radio  ,Pagination, showToast,showLoadingToast,Icon } from 'vant'
import { store } from '@/store/index';
import { useRouter } from 'vue-router';
import 'vant/lib/index.css';
import * as XLSX from 'xlsx';
import { createAdmin, modifySecondAdmin, delAdmin, update_excel } from '@/api/index'
import { set } from '@vueuse/core';
const page = getCurrentInstance()
const add_address_show = ref(false)
const select_address_type = ref(1)
const show_data = ref([])
// 查询等待
const query_loading = ref(false)
const one_update_address = ref({
    shopsName:'',
    province:'',
    city:'',
    county:'',
    address:''
})
// 设置查询范围
const addres_range = async ()=>{
    
    let rangeRes = await page.proxy.$apiGet('/get_Range')
    console.log(rangeRes)
    let prores = prompt('当前查询范围为:'+rangeRes.data[0].range+'公里')
    if(prores!=''){
        let number = prores.match(/\d+/g)
        let range_updateLoading = showLoadingToast({
            message: '更新中...',
            loadingType: 'spinner',
            duration:0
        });
        setTimeout(async ()=>{
            let res = await page.proxy.$apiGet('/modifyQuery_range',{range:number[0]})
            range_updateLoading.close()
            showToast('更新'+res.data)
        },500)
    }
}
// 二次确认地址
const seconde_isShow = ref(false)
const checked = ref(0)
const query_result = ref([])
// excel数据
const excelData = ref([])
// excel上传锁
const excel_clock = ref(false)
//excel上传进度
const excel_update_schedule = ref({
    num:0,
    total:0,
})
// 分页器数据
const totalPage = ref(1)
const pageInfo = ref({
  items_per_page:15,//每页记录数	
  total_items:1,//总记录数
  show_page_size:5, //显示的页码个数
  dataTotal:1 // 数据总条数
})
onBeforeMount(async () => {

})
onMounted( async() => {
    let res = await page.proxy.$apiGet('/jmqzgetAddress',{pageNum:1})
    pageInfo.value.dataTotal = res.data.count
    pageInfo.value.total_items = res.data.count
    show_data.value =  res.data.rows
})
const cahangePage = async ()=>{
    let res = await page.proxy.$apiGet('/jmqzgetAddress',{pageNum:totalPage.value})
    show_data.value =  res.data.rows
    console.log(res)
}
const close_address_overlay = ()=>{
    if(seconde_isShow.value || excelData.value.length>0){
        return
    }
    add_address_show.value = false
}
const add_address = ()=>{
    add_address_show.value = true
}
const queryAddress = async ()=>{
    const textLeg = /[\u4e00-\u9fff]/ //汉字验证正则
    if(!textLeg.test(one_update_address.value.shopsName)){
        one_update_address.value.shopsName = ''
        showToast('商铺名仅支持中文')
        return
    }
    if(!textLeg.test(one_update_address.value.province)){
        one_update_address.value.province=''
        showToast('省份仅支持中文')
        return
    }
    if(!textLeg.test(one_update_address.value.city)){
        one_update_address.value.city=''
        showToast('城市仅支持中文')
        return
    }
    if(!textLeg.test(one_update_address.value.county)){
        one_update_address.value.county = ''
        showToast('区县仅支持中文')
        return
    }
    query_loading.value = true
    
    const { shopsName,address,city,county } = one_update_address.value
    let addressInfo =city+county+ address
    let res = await page.proxy.$apiGet('/jmqzaddressAnalysis',{'city':city,'address':addressInfo})
    query_result.value = res.data.result.address_components
    query_result.value.location =  res.data.result.location
    setTimeout(async ()=>{
        query_loading.value = false
        if(res.data.message!='Success'){
            showToast('查询失败')
            return
        }
        let updateres = await page.proxy.$apiGet('/jmqzupdateAddress',{...getUpdate_data(shopsName,address)})
        showToast({
            message: '上传成功',
        })
        query_result.value = []
        one_update_address.value = ref({shopsName:'',province:'',city:'',county:'',address:''})
        cahangePage()
    },300)
}
const upLoad_barChange = (type)=>{
    select_address_type.value = type
}
// 确定上传
// const confirm_update = async ()=>{
//     let res = await page.proxy.$apiGet('/updateAddress',{...getUpdate_data()})
//     showToast({
//         message: '上传成功',
//         teleport:'.second_confirm'
//     })
//     if(excel_update_schedule.value.total>0){
//         if(excel_update_schedule.value.num<excel_update_schedule.value.total-1){
//             excel_update_schedule.value.num +=1
//             let i  = excel_update_schedule.value.num
//             let city = excelData.value[i][1]
//             let addressInfo = excelData.value[i][1]+excelData.value[i][2]+excelData.value[i][3]+excelData.value[i][4]
//             let res = await page.proxy.$apiGet('/addressAnalysis',{'city':city,'address':addressInfo})
//             query_result.value = res.data.geocodes
//         }else{
//             alert('已完成excel表格上传')
//             add_address_show.value = true
//             seconde_isShow.value = false
//             excel_update_schedule.value = { num:0,total:0}
//             excelData.value = []
//             // const fileInput = document.getElementById('fileInput');
//             // fileInput.value = ''
//         }

//     }else{
//         console.log('111')
//         setTimeout(()=>{
//             seconde_isShow.value = false
//             query_result.value = []
//             one_update_address.value = { shopsName:'',province:'',city:'',county:'',address:'' }
//             showToast('新增'+res.data)
//         },300)
//     }
    
//     // 更新页面数据
//         setTimeout(async()=>{
//             totalPage.value = 1
//             let getres = await page.proxy.$apiGet('/getAddress',{pageNum:1})
//             console.log(getres)
//             pageInfo.value.dataTotal = getres.data.count
//             pageInfo.value.total_items = getres.data.count
//             show_data.value =  getres.data.rows
//             console.log(show_data.value)
//         },300)
// }
// excel上传
const handleFileUpload =(event)=>{
    let excel_data_loading = showLoadingToast({
        message: 'excel解析中...',
        loadingType: 'spinner',
        duration:0
    });
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        // 处理jsonData，例如将其存储到Vuex或者状态中
        const filteredData = jsonData.filter(row => row.length > 0);
        const cleanedData = filteredData.map(row => row.filter(field => field !== ""));
        let trimmedData = filteredData.slice(1);
        trimmedData = trimmedData.map(row => row.slice(0));
        excel_data_loading.close()
        if(trimmedData.length==0) {
            showToast('该表格无数据，请重新选择')
            return
        }
        excelData.value = trimmedData
    };
    reader.readAsArrayBuffer(file);
}
// 上传数据整合
const getUpdate_data = (shopsName,address)=>{
    let { city,district,province,location } = query_result.value
    let latitude = location.lat
    let longitude = location.lng
    let params = {
        'province':province,
        'city':city,
        'county':district,
        'address':district+address,
        'shopsname':shopsName,
        'latitude':latitude,
        'longitude':longitude
    }
    return params
}
// excel开始上传
const excel_update = () =>{
    if(excel_clock.value) return
    let excel_data_inspect = showLoadingToast({
        message: '数据校验中...',
        loadingType: 'spinner',
        duration:0,
        teleport:'.excel_data_show'
    });
    // 模拟延时
    setTimeout(async() => {
        let excel_upData = excelData.value
        let data_isOk = true
        excel_upData.forEach(async (item,i) => {
            for(let a = 0;a<5;a++){
                if(item[a]=== '' ||item[a]=== null  || item[a]=== undefined ){
                    excel_data_inspect.close()
                    alert('第'+(i+1)+'数据出现问题请检查')
                    data_isOk = false
                    return
                }
            }
        });

        let i = 0
        if(!data_isOk) return 
        excel_data_inspect.close()
        excel_update_schedule.value.total =  excelData.value.length
        let upload_loading = showLoadingToast({
            message: '上传中,请勿刷新页面...',
            loadingType: 'spinner',
            duration:0,
            teleport:'.excel_data_show'
        });
        let timer = setInterval(async () => {
            if(i>=excel_update_schedule.value.total){
                clearInterval(timer)
                upload_loading.close()
                showToast('上传完成')
                close_excel_data()
                return
            }
            let city = ''
            let addressInfo = ''
            city = excelData.value[i][1]
            addressInfo = excelData.value[i][1]+excelData.value[i][2]+excelData.value[i][3]+excelData.value[i][4]
            console.log(city +' '+ addressInfo)
            let res = await page.proxy.$apiGet('/jmqzaddressAnalysis',{'city':city,'address':addressInfo})
            if(res.data.status !=0){
                alert(excelData.value[i][0]+'商铺'+res.data.message) 
                clearInterval(timer)
                upload_loading.close()
                query_result.value = []
                return
            }else{
                query_result.value = res.data.result.address_components
                query_result.value.location =  res.data.result.location
                let shopsName = ''
                let address = ''
                shopsName = excelData.value[i][0]
                address = excelData.value[i][4]
                let updateres = await page.proxy.$apiGet('/jmqzupdateAddress',{...getUpdate_data(shopsName,address)})
                query_result.value = []
                cahangePage()
            }
            i+=1
        }, 500);
       
    }, 1000); 
}
// 关闭excel数据展示
const close_excel_data = ()=>{
    
    if(excel_clock.value) return
    excelData.value = []
    const fileInput = document.getElementById('fileInput');
    fileInput.value = ''
}
// 删除数据
const delAddress_click = async (item)=>{
    let res = confirm('确认删除店铺名为:'+item.shopsname+'的数据吗?删除后将无法恢复')
    if(!res) return
    let del_loading = showLoadingToast({
        message: '删除中',
        loadingType: 'spinner',
        duration:0
    });
    setTimeout( async() => {
        let isDel = await page.proxy.$apiGet('/jmqzdelAddress',{id:item.id})
        del_loading.close()
        cahangePage()
        showToast('删除'+isDel.data)
    }, 1000);
}
</script>
<style scoped>
.addressManage{
    height: 92.5%;
    text-align: right;
    position: relative;
}
.addres_query_range{
    width:25px;
    height: 25px;
    position: absolute;
    right: 50px;
    top: 0;
    cursor: pointer;
}
.van-icon {
    font-size: 25px;
}
.not_data{
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 70px;
    color:#ddd;
    transform: translate(-30%,-50%);
}
.show_data{
    width: 100%;
    height: 100%;
}
.show_data_title{
    height: 75px;
    display: flex;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    padding-top: 45px;
    border-bottom: 1px #ddd dashed;
}
.show_data_title>p{
    flex: 0.17;
    text-align: center;
}
.show_data_ing li{
    display: flex;
    min-height: 30px;
    align-items: center;
}
.show_data_ing li:hover{
    color: #1989fa;
}
.show_data_ing li p{
    flex: 0.167;
    text-align: center;
    font-size: 15px;
}
.delAddress{
    color: red;
    text-decoration: underline;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
.add_address{
    font-size: 70px;
    background: rgba(22, 22, 245, 0.3);
    position: absolute;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    color: #fff;
    border-radius: 50%;
    bottom: 50px;
    right: 60px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1),-5px -5px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.paging{
    width: 300px;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: right;
}
.address_wrapper{
    width: 300px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 20px;
    text-align: center;
    border-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
    overflow: hidden;
}
.add_address_type{
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-bottom:  1px solid #ddd;
}

.add_address_type>p{
  flex: 0.5;
  cursor: pointer;
  padding-bottom: 7px;
}
.add_address_type>p:first-of-type{
  border-right: 1px solid #ddd;
}
.select_add_type{
  color: rgba(22, 22, 245, 0.3) !important;
}
.one_update_tips{
    font-size: 11px;
    color: #ddd;
    text-align: left;
    padding-left: 19px;
    position: relative;
    margin-top: 7px;
}
.one_update_tips::before{
    height: 100%;
    content: '*';
    position: absolute;
    top:25%;
    left: 10px;
}
.fileUploader{
  font-size: 17px;
  margin: 22px 0;
}
.submit{
    margin-top: 13px;
}
.second_confirm{
    min-width: 300px;
    min-height: 50px;
    border-radius: 15px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding:15px 20px;
    z-index: 5001;
    text-align: left;
}
.address_confirm_title{
    text-align: center;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    font-size: 20px;
    position: relative;
}
.close_second_address{ 
    width: 15px;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 1px;
}
.close_second_address>img{ 
   width: 100%;
   height: 100%;
}
.text_Address{    
    padding: 0.07rem;
    line-height: 0.25rem;
    border-bottom: 0.01rem #ddd solid;
    margin-bottom: 6px;
    font-size: 13px;
}
.text_Address span{ 
    font-weight: 600;
}
.Radio{
    font-size: 15px !important;
}
.van-radio__icon{
    position: absolute !important;
    top: 0;
}
.van-radio{
    margin-top: 10px !important;
    text-wrap: nowrap;
}
.second_btn{
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}
.add_address_submit{
    padding:0 13px !important;
}

.excel_data_show{
  width: 70%;
  height: 80%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  z-index: 1009;
  overflow: hidden;
}
.excel_show_title{
    border-bottom: 1.5px solid #ddd;
    padding-bottom: 10px;
    font-size: 30px;
    font-weight: 500;
    position: relative;
}
.excel_show_title>div{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 15px;
    top: 0px;
    cursor: pointer;
    text-align: center;
}
.excel_show_title>div img{
    width: 20px;
    height: 20px;
}
.excel_show_content{
    margin-top: 15px;
    overflow-y: auto;
    height: 84%;
}
.excel_show_type{
    display: flex;
    margin-top: 15px;
}
.excel_show_type>p{
    flex: 0.2;
    text-align: center;
    font-size: 20px;
}
.excel_show_content>ul>li{
    display: flex;
    padding: 15px 0;
    box-sizing: border-box;
}
.excel_show_content>ul>li:not(:last-child){
    border-bottom: 1px dashed #ddd;
}
.excel_show_content>ul>li>p{
    flex: 0.2;
    text-align: center;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.excel_show_content>ul>li>p:last-of-type{
    text-align: left;
}
.excel_update{
    color: #1989fa;
    text-decoration: underline;
    font-size: 15px;
    font-weight: 500;
    position: absolute;
    top: 28Px;
    right: 15Px;
    cursor: pointer;
}
.update_schedule{
    font-weight: 500;
    font-size: 15px;
}
.update_schedule>span{
    font-size: 18px;
    color: #1989fa;
}
</style>