
import {useRouter } from 'vue-router';
export const startAutoLogout = () => {
    
    const router = useRouter();
    const timeout = 30 * 60 * 1000; // 30分钟
    // const timeout = 5 * 1000; // 5秒
    let timer;
  
    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        // 执行退出操作，例如清除登录状态
        localStorage.setItem('isLogin',false)
        router.push('/')
      }, timeout);
    };
  
    resetTimer();
  
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('keydown', resetTimer);
};